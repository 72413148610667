function loadMore(uM) {
	var target = $(uM.attr('data-target')),
		start = parseInt(uM.attr('data-start')),
		step = parseInt(uM.attr('data-step')),
		end = parseInt(uM.attr('data-end')),
		current = start + step,
		url = uM.attr('data-url') + start;
	uM.hide();
	$.get({
		url: url,
		success: function (data) {
			if (target.find(uM.parent()).length) {
				uM.parent().before($.parseHTML(data));
			} else if (target.find(uM).length) {
				uM.before($.parseHTML(data));
			} else {
				target.append($.parseHTML(data));
			}
			switcher(target.find('.switch'));
			textCut(target.find('.dot'));
			if (current < end) {
				uM.show();
				uM.attr('data-start', current);
			} else {
				uM.attr('data-start', end);
			}
		}
	});
}
function commentaryField(uM) {
	var target = uM.parent(),
		url = uM.attr('data-url');
	$.get({
		url: url,
		success: function (data) {
			target.after($.parseHTML(data));
			switcher(target.next().find('.switch'));
			uM.hide();
		}
	});
}
function loadFiltred(uM) {
	var url = uM.attr('data-url'),
		suffix = uM.val(),
		target = $(uM.attr('data-target')), 
		relation = uM.attr('data-relation');
	if (suffix && suffix.length > 0) {
		url = url + suffix;
	}
	$.get({
		url: url,
		success: function (data) {
			uM.addClass('active').siblings().removeClass('active');
			target.html($.parseHTML(data));
			if (relation && relation === 'parent') {
				$(uM).parent().toggleClass('active');
			}
			switcher(target.find('.switch'));
			textCut(target.find('.dot'));
		}
	});
}
function pollActivity(uM) {
	var url = uM.attr('data-url'),
		target = $(uM.attr('data-target')),
		relation = uM.attr('data-relation');
	$.get({
		url: url,
		success: function (data) {
			if (relation && relation === 'parent') {
				target = $(uM).closest(target);
			}
			target.html($.parseHTML(data));
		}
	});
}
function changePhoto(uM) {
	var reader = new FileReader(),
		uM = $(uM),
		name = $(uM.data('name')),
		preview = $(uM.data('preview'));
	name.text(uM[0].files[0].name);
	reader.onload = function () {
		preview.attr('src', reader.result);
	};
	reader.readAsDataURL(uM[0].files[0]);
}
function addImages(uM) {
	var reader = new FileReader(),
		uM = $(uM),
		url = uM.data('url'),
		removeUrl = uM.data('remove'),
		fileLIst = uM[0].files,
		temaplate = uM.data('template'),
		target = uM.data('target');
	if (!isEmpty(fileLIst)) {
		$.get({
			url: url + fileLIst,
			success: function (data) {
				for (var file in fileLIst) {
					if (fileLIst.hasOwnProperty(file)) {
						var fileName = $('<span/>', {
								text: fileLIst[file].name
							}),
							removeIco = $('<a/>', {
								href: '#',
								class: 'switch remove',
								'data-role': 'ajax',
								'data-function': 'removeImages',
								'data-url': removeUrl,
								'data-target': temaplate,
								'data-name': fileLIst[file].name
							});
						$('<div/>', {
							class: temaplate,
							html: fileName
						}).append(removeIco).appendTo(target);
					}
				}
				switcher($(target).find('.switch'));
			}
		});
	}
}
function removeImages(uM) {
	var uM = $(uM),
		url = uM.data('url'),
		name = uM.data('name'),
		target = uM.data('target');
	if (url && name && target && confirm("Вы уверенны?") === true) {
		$.get({
			url: url + name,
			success: function (data) {
				uM.closest('.'+target).remove();
			}
		});
	}
}
function removeTag(uM) {
	var uM = $(uM),
		url = uM.data('url'),
		name = uM.data('name'),
		target = uM.data('target');
	if (url && name && target && confirm("Вы уверенны?") === true) {
		$.get({
			url: url + name,
			success: function (data) {
				uM.closest('.'+target).remove();
			}
		});
	}
}
function addTag(uM) {
	var uM = $(uM),
		url = uM.data('url'),
		target = uM.data('target');
	if (url && name && target) {
		$.get({
			url: url + name,
			success: function (data) {
				uM.closest(target).remove();
			}
		});
	}
}
function getModal(uM) {
	var target = $(uM.attr('data-target')),
		url = uM.attr('data-url');
	uM.hide();
	$.get({
		url: url,
		success: function (data) {
			if(target.hasClass('modaled')) {
				$('#modal').remove();
				target.removeClass('modaled');
			}
			target.append($.parseHTML(data)).addClass('modaled');
			switcher(target.find('.switch'));
			uM.show();
		}
	});
}
function leaveModal(uM) {
	var target = $(uM.attr('data-target'));
	if (target.hasClass('modaled')) {
		$('#modal').remove();
		target.removeClass('modaled');
	}
}
function authorization(uM) {
	var target = $(uM.attr('data-target')),
		url = uM.attr('data-url');
	uM.hide();
	$.get({
		url: url,
		success: function (data) {
			if (target.hasClass('modaled')) {
				$('#modal').remove();
				target.removeClass('modaled');
			}
			target.append($.parseHTML(data)).addClass('modaled');
			switcher(target.find('.switch'));
			uM.show();
		}
	});
}
function auth(uM) {
	var target = $(uM.attr('data-target')),
		form_dom = target.get(0),
		new_href = uM.attr('data-href'),
		message_block = $(uM.attr('data-message'));
		uM.hide();
	$.ajax({
		url: target.attr('action'),
		type: "POST",
		data: new FormData(form_dom),
		dataType: 'json',
		success: function (result) {
			if (result.status == 'ok') {
				if (!!result.message) {
					message_block.removeClass('error, success').text(result.message).addClass('success').show();
				}
				uM.show();
				if (!!new_href) {
					$(location).attr('href', new_href);
				}
			} else {
				if (!!result.message) {
					message_block.removeClass('error, success').text(result.message).addClass('error').show();
				}
				uM.show();
				return false;
			}
		},
		error: function (result) {
			var message = result.statusText;
			if (!!result.message) {
				message = result.message;
			}
			message_block.removeClass('error, success').text(message).addClass('error').show();
			uM.show();
			return false;
		},
		cache: false,
		contentType: false,
		processData: false
	});
}
$(document).ready(function () {
	var games_select = $('select.table-box-select');
	if (games_select.length) {
		loadFiltred(games_select);
	}
});